import axios from 'axios';
import {getIPAddress, getDeviceType, getBrowserName} from 'helpers/helpers';
// import {API_URL} from 'core/config'

// let baseUrl = window.location.href.includes(`file`) || window.location.href.includes(`localhost` || `dev-portal`) ? `https://dev-api.homesfy.in/api` : `https://api.homesfy.in/api`;
let baseUrl = window.location.href.includes(`file`) || window.location.href.includes(`localhost`) || window.location.href.includes(`dev-portal`) ? `https://dev-api.homesfy.in/api` : `https://api.homesfy.in/api`;

/*
 * const createQueryString = (data) => {
 * 	return Object.keys(data).map(key => {
 * 		let val = data[key];
 */

/*
 * 		if (val !== null && typeof val === `object`) {
 * 			val = createQueryString(val);
 * 		}
 * 		return `${key}=${encodeURIComponent(`${val}`.replace(/\s/ug, ` `))}`;
 * 	}).join(`&`);
 * };
 */

const SaveLead = async(
	name, email, number, countryCode, projectId, pref, adminMessage, terms, loan, onSuccess,
) => { // eslint-disable-line max-params
	let formData = Object();
	let siteData = window.siteData;

	formData.name = name;
	formData.email = email;
	formData.number = number;
	formData.country_code = countryCode;
	formData.project_id = projectId;
	formData.admin_message = adminMessage;
	formData.source_id = 53;
	formData.is_tc_agree = terms;
	formData.is_homesfy_brand = 1;
	formData.more_fields = {};
	formData.more_fields.loan_required = loan;

	formData.Digital = {};
	formData.Digital.user_device = getDeviceType();
	formData.Digital.user_browser = getBrowserName();
	formData.Digital.launch_name = siteData?.utm_campaign || null;
	formData.Digital.client_ipaddress = await getIPAddress();
	formData.Digital.client_pref = pref;

	formData.Utm = {};
	[`medium`, `campaign`, `source`, `content`, `term`]?.map(item => {
		if (siteData[`utm_${item}`]) {
			formData.Utm[`utm_${item}`] = siteData[`utm_${item}`];
		}
		return false;
	});
	axios.post(`${baseUrl}/leads/create`, formData).then(() => {
		onSuccess?.();
	});
};

export default SaveLead;
