import SaveLead from 'components/save-lead';

const bindCtaForm = (cls, onSuccess) => {
	const ctaForms = document.querySelectorAll(cls);

	for (let i = 0; i < ctaForms.length; i++) {
		let ctaForm = ctaForms[i];

		ctaForm.addEventListener(`submit`, (event) =>{
			event.preventDefault();
			let formData = new FormData(event.currentTarget);

			formData = Object.fromEntries(formData.entries());
			let name = formData.fullname ? formData.fullname : null;
			let email = formData.email ? formData.email : null;
			let number = formData.phoneno ? formData.phoneno : null;
			let countryCode = formData[`country-code`] ? formData[`country-code`] : null;
			let adminMessage = formData.adminMessage ? formData.adminMessage : null;
			let pref = formData.pref;
			let projectId = formData.projectId;
			let terms = document.getElementById(`terms`)?.checked ? 1 : 0;
			let loan = document.getElementById(`loan`)?.checked ? 1 : 0;

			// console.log(formData);
			SaveLead(name, email, number, countryCode, projectId, pref, adminMessage, terms, loan, onSuccess);
			ctaForm.reset();
			return false;
		});
	}
};

export default bindCtaForm;
