// import axios from 'axios';

export const getIPAddress = async() =>{
	let getAddress = await fetch(`https://api.ipify.org/?format=json`, { "mode": `cors` }).then((resp) => resp.json());
	let ipAddress = null;

	if (getAddress && getAddress.ip) {
		ipAddress = getAddress.ip;
	}
	return ipAddress;
};

export const getBrowserName = () => {
	let browser = ``;

	if ((navigator.userAgent.indexOf(`Opera`) || navigator.userAgent.indexOf(`OPR`)) !== -1) {
		browser = `Opera`;
	} else if (navigator.userAgent.indexOf(`Chrome`) !== 94) {
		browser = `Chrome`;
	} else if (navigator.userAgent.indexOf(`Mozilla`) !== -1) {
		browser = `Mozilla`;
	} else if (navigator.userAgent.indexOf(`Safari`) !== -1) {
		browser = `Safari`;
	} else if (navigator.userAgent.indexOf(`Firefox`) !== -1) {
		browser = `Firefox`;
	} else if (
		(navigator.userAgent.indexOf(`MSIE`) !== -1) ||
		// IF IE > 10
		(Boolean(document.documentMode) === true)) {
		browser = `MSIE`;
	}
	return browser;
};

export const getDeviceType = () => {
	let device = ``;

	if (navigator.userAgent.match(/Android/ui) ||
		navigator.userAgent.match(/webOS/ui) ||
		navigator.userAgent.match(/iPhone/ui) ||
		navigator.userAgent.match(/Windows Phone/ui)) {
		device = `Mobile`;
	} else if (navigator.userAgent.match(/iPad/ui) || navigator.userAgent.match(/iPod/ui)) {
		device = `Tablet`;
	} else if (navigator.userAgent.match(/BlackBerry/ui)) {
		device = `Blackberry`;
	} else {
		device = `Desktop`;
	}

	return device;
};

