import bindEvent from "helpers/event-bind";
import device from "helpers/device";

const createSlider = (target, opts = {}) => {
	let options = {
		"autoplay": {
			"desktop" : false,
			"mobile"  : true,
		},
		"progress"    : ``,
		"sliderCount" : {
			"desktop" : 3,
			"mobile"  : 1,
		},
		...opts,
	};

	const sliderContainer = target.getElementsByClassName(`slider-wrap`)[0];
	const slider = target.getElementsByClassName(`slider-main`)[0];
	let slides = target.querySelectorAll(`.slideItem`);
	let progressBar = target.querySelector(`.progressbar`);
	let progressIndicators = target.querySelector(`.progress-indicators`);
	let progress = target.querySelector(`.progress`);

	let noOfClicks = 0;
	let noOfSlides = slides.length;
	let noOfSlidesToDisplay = options.sliderCount[device];
	let autoplayfunction = options.autoplay[device];

	const updateDimensions = () => {
		let containerWidth = sliderContainer.clientWidth;
		let slideWidth = containerWidth / noOfSlidesToDisplay;

		for (let i = 0; i < slides.length; i++) {
			slides[i].style.width = `${slideWidth}px`;
		}
		slider.style.width = `${noOfSlides * slideWidth}px`;
	};

	const handlePrevNext = () => {
		if ((noOfSlides - noOfSlidesToDisplay) <= noOfClicks) {
			target.getElementsByClassName(`next-btn`)[0].style.display = `none`;
		} else {
			target.getElementsByClassName(`next-btn`)[0].style.display = `block`;
		}

		if (noOfClicks === 0) {
			target.getElementsByClassName(`prev-btn`)[0].style.display = `none`;
		} else {
			target.getElementsByClassName(`prev-btn`)[0].style.display = `block`;
		}
	};

	// Handle progress bar
	const handleProgressBar = () => {
		if (!progressBar) {
			return false;
		}

		progressBar.style.width = `${((noOfClicks + noOfSlidesToDisplay) / noOfSlides) * 100}%`;
		if (options.progress !== `progress-indicator`) {
			progress.style.display = `block`;
		}
		return false;
	};

	// Handle progress indicators
	const handleProgressIndicator = () => {
		if (!progressIndicators) {
			return false;
		}

		progressIndicators.innerHTML = ``;
		for (let i = 1; i <= slides.length; i++) {
			let li = document.createElement(`div`);

			li.className = `progress-indicator`;
			li.innerHTML = `<div class="progress-fill"></div>`;
			progressIndicators.appendChild(li);
		}

		const elmnt = document.querySelectorAll(`.progress-indicator`);
		const numberOfelmnt = elmnt.length;
		const activeClass = `activeSlide`;

		const repeat = (numberOfIterations, timeBetweenIteration, stuffToRepeat) => {
			let iterationCounter = 0;
			const repeater = () => {
				setTimeout(() => {
					stuffToRepeat(elmnt[iterationCounter], activeClass);
					iterationCounter++;
					if (numberOfIterations === iterationCounter) {
						iterationCounter = 0;
					}
					if (iterationCounter >= numberOfIterations) {
						return;
					}
					repeater();
				}, 3000 * timeBetweenIteration);
			};

			repeater();
		};

		const addClassToElmnt = (elmn, className) => {
			let elmntWithClassToRemove = document.getElementsByClassName(className);

			[...elmntWithClassToRemove].forEach((currentElmnt) => {
				currentElmnt.classList.remove(className);
			});
			elmn.classList.add(className);
		};

		repeat(numberOfelmnt, 1, addClassToElmnt);
		return false;
	};

	const handleNext = (e) => {
		e?.stopPropagation();
		noOfClicks += 1;
		if (noOfClicks > noOfSlides - noOfSlidesToDisplay) {
			noOfClicks = noOfSlides - noOfSlidesToDisplay;
		}
		slider.style.transform = `translateX(-${noOfClicks * sliderContainer.clientWidth / noOfSlidesToDisplay}px)`;
		handlePrevNext();
		handleProgressBar();
		return false;
	};

	const handlePrev = (e) => {
		e?.stopPropagation();
		noOfClicks -= 1;
		if (noOfClicks < 0) {
			noOfClicks = 0;
		}
		slider.style.transform = `translateX(-${noOfClicks * sliderContainer.clientWidth / noOfSlidesToDisplay}px)`;
		handlePrevNext();
		handleProgressBar();
		return false;
	};

	const autoplay = () => {
		if (autoplayfunction) {
			setInterval(handleNext, 3000);
		}
	};

	bindEvent(`.prev-btn`, `click`, handlePrev, target);
	bindEvent(`.next-btn`, `click`, handleNext, target);
	updateDimensions();
	handlePrevNext();
	handleProgressBar();
	handleProgressIndicator();
	autoplay();
	window.addEventListener(`resize`, updateDimensions);
};

const attachSlider = (selector, options) => {
	const sliders = document.querySelectorAll(selector);

	for (let j = 0; j < sliders.length; j++) {
		createSlider(sliders[j], options);
	}
};

export default attachSlider;
