const bindEvent = (selector, eventName, callback, parentSelector) => {
	if (parentSelector instanceof HTMLElement) {
		let parentNode = parentSelector;
		let targets = parentNode.querySelectorAll(selector);

		for (let j = 0; j < targets.length; j++) {
			targets[j].addEventListener(eventName, callback);
		}
	} else if (parentSelector) {
		let parentNodes = document.querySelectorAll(parentSelector);

		for (let i = 0; i < parentNodes.length; i++) {
			let parentNode = parentNodes[i];
			let targets = parentNode.querySelectorAll(selector);

			for (let j = 0; j < targets.length; j++) {
				targets[j].addEventListener(eventName, callback);
			}
		}
	} else {
		let targets = document.querySelectorAll(selector);

		for (let j = 0; j < targets.length; j++) {
			targets[j].addEventListener(eventName, callback);
		}
	}
};

export default bindEvent;
