import { bindMenuAction } from 'components/main-menu';
import bindFloatMenu from 'components/float-menu';
import handleCopyText from 'components/copy-text';
import SaveLead from 'components/save-lead';
import bindModalAction from 'components/modal';
import bindCtaForm from 'components/cta-form';
import bindEvent from 'helpers/event-bind';
import attachSlider from 'components/slider';
import device from 'helpers/device';

window.handleCopyText = handleCopyText;
window.SaveLead = SaveLead;
const onSuccess = () => {
	document.getElementById(`success-modal`).style.display = `block`;
	document.getElementById(`cta-modal`).style.display = `none`;
};

const handleSocial = (e) => {
	e.stopPropagation();
};

bindEvent(`.SocialCard`, `click`, handleSocial);

const main = () => {
	bindMenuAction(`act-click-open-nav`, `act-click-close-nav`);
	bindFloatMenu(`.act-click-share-btn`);
	bindModalAction(`.enq_btn`, `close-modal`);
	bindCtaForm(`.cta-form`, onSuccess);
	bindCtaForm(`.enquiry-form`, onSuccess);
	if (device === `desktop`) {
		attachSlider(`.featured-projects-card-list`, {
			"autoplay": {
				"desktop" : false,
				"mobile"  : false,
			},
			"progress": `bar`,
		});
		attachSlider(`.recently-added-project-card-list`, {
			"autoplay": {
				"desktop" : false,
				"mobile"  : false,
			},
			"progress": `bar`,
		});
		attachSlider(`.featured-builders-card-list`, {
			"autoplay": {
				"desktop" : false,
				"mobile"  : false,
			},
			"progress": `bar`,
		});
		attachSlider(`.featured-developers-card-list`, {
			"autoplay": {
				"desktop" : false,
				"mobile"  : false,
			},
			"progress"    : `bar`,
			"sliderCount" : {
				"desktop" : 5,
				"mobile"  : 1,
			},
		});
	}

	// eslint-disable-next-line max-statements-per-line
	// window.onresize = function() { location.reload(); };
};

main();
