const openBox = (openTargetNode) => {
	//let isOpen = false;

	openTargetNode.addEventListener(`click`, (e) => {
		if (e.currentTarget !== openTargetNode) {
			return true;
		}
		/*
		 * if(openTargetNode)
		 * if (openTargetNode.querySelectorAll(e.target).length) {
		 * 	return true;
		 * }
		 */
		e.stopPropagation();

		let callAgentViewNode = document.getElementById(openTargetNode.dataset.target);

		if (callAgentViewNode.style.display === `block`) {
			callAgentViewNode.style.display = `none`;
		}

		let allpossibleopentargets = document.querySelectorAll(`.${e.currentTarget.className.split(` `).join(`.`)}`);

		for (let i = 0; i < allpossibleopentargets.length; i++) {
			let currenttarget = allpossibleopentargets[i];
			let callAgentViewNode1 = document.getElementById(currenttarget.dataset.target);

			callAgentViewNode1.style.display = `none`;
		}
		// console.log(openTargetNode);

		/*
		 * if (isOpen) {
		 * 	callAgentViewNode.style.display = `none`;
		 * } else {
		 * }
		 */
		callAgentViewNode.style.display = `block`;

		/* This handles click on document: close menu if clicked */
		const closeAction = (event) => {
			if (!callAgentViewNode.contains(event.target)) {
				callAgentViewNode.style.display = `none`;
				document.removeEventListener(`click`, closeAction);
			}
		};

		document.addEventListener(`click`, closeAction);
		return false;
	});
};

const bindFloatMenu = (openTarget) => {
	const openTargetNodes = document.querySelectorAll(openTarget);

	if (!openTarget?.length || openTarget?.length === 0) {
		return;
	}
	for (let i = 0; i < openTargetNodes.length; i++) {
		let openTargetNode = openTargetNodes[i];

		openBox(openTargetNode);
	}
};

export default bindFloatMenu;
