const handleCopyText = (target) => {
	const text = target.dataset.text;

	navigator.clipboard.writeText(text);
	// eslint-disable-next-line no-alert
	// alert(`Text copied: ` + text);
	console.log(`Text copied: ` + text);
};

export default handleCopyText;
