const bindModalAction = (openTargetNode, closeTargetNode) => {
	const openTargets = document.querySelectorAll(openTargetNode);

	if (!openTargets?.length) {
		console.error(`open target not found`);
		return;
	}
	for (let i = 0; i < openTargets.length; i++) {
		let openTarget = openTargets[i];

		openTarget.addEventListener(`click`, (event) => {
			let currentTarget = event.currentTarget;
			let pref = currentTarget?.dataset?.pref;
			let projectId = currentTarget?.dataset?.projectid;

			document.getElementById(`cta-modal`).style.display = `block`;
			document.getElementById(`cta-modal`).getElementsByClassName(`pref`)[0].setAttribute(`value`, pref);
			document.getElementById(`cta-modal`).getElementsByClassName(`projectId`)[0].value = projectId;
			const closeTarget = document.getElementById(closeTargetNode);
			const closeModal = () => {
				document.getElementById(`cta-modal`).style.display = `none`;
				closeTarget.removeEventListener(`click`, closeModal);
			};

			closeTarget.addEventListener(`click`, closeModal);
		});
	}
};

export const bindModal = (openTargetNode, closeTargetNode, target) => {
	const openTargets = document.querySelectorAll(openTargetNode);

	if (!openTargets?.length) {
		console.error(`open target not found`);
		return;
	}
	for (let i = 0; i < openTargets.length; i++) {
		let openTarget = openTargets[i];

		openTarget.addEventListener(`click`, () => {
			document.getElementById(target).style.display = `block`;
			/*
			 * const filterForm = document.getElementById(`listing-filter-form`);
			 * const clone = filterForm.cloneNode(true);
			 */

			/*
			 * document.getElementById(`filter-form-holder`).appendChild(clone);
			 * filterForm.remove();
			 */

			const closeTarget = document.getElementById(closeTargetNode);
			const closeModal = () => {
				document.getElementById(target).style.display = `none`;
				closeTarget.removeEventListener(`click`, closeModal);
			};

			closeTarget.addEventListener(`click`, closeModal);
		});
	}
};

export default bindModalAction;
