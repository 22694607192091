export const bindMenuAction = (openTargetNode, closeTargetNode) => {
	const openTarget = document.getElementById(openTargetNode);

	if (!openTarget) {
		console.error(`open target not found`);
		return;
	}

	openTarget.addEventListener(`click`, () => {
		// document.getElementById(`fullpage_nav`).style.display = `block`;
		document.getElementById(`fullpage_nav`).style.height = `100vh`;
		const closeTarget = document.getElementById(closeTargetNode);
		const closeMenu = () => {
			// document.getElementById(`fullpage_nav`).style.display = `none`;
			document.getElementById(`fullpage_nav`).style.height = 0;
			closeTarget.removeEventListener(`click`, closeMenu);
		};

		closeTarget.addEventListener(`click`, closeMenu);
	});
};
